var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-modal',{attrs:{"body-class":"p-0","size":"xl","id":("invoice-recon-modal-" + _vm.componentId)}},[(_vm.activeReconciliationId != null)?_c('edit-reconciliation-modal-inner',{attrs:{"reconciliationId":_vm.activeReconciliationId},on:{"reconciliation-saved":_vm.handleReconciliationSaved}}):_vm._e()],1),_c('confirm-modal',{ref:"quickactionmodalconfirmModal",attrs:{"refname":_vm.randomName}}),_c('posting-preview-modal',{ref:"postingPreviewModal"}),_c('send-failed-import-feedback-modal',{ref:"sendFailedImportFeedbackModal"}),_c('b-overlay',{attrs:{"show":_vm.blockedLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"responsive":"","show-empty":"","primary-key":"id","empty-text":_vm.emptyText == undefined
          ? this.T('Web.Generic.TableEmpty', 'No items to show')
          : _vm.emptyText,"fields":_vm.invoiceTableHeadersFields,"items":_vm.listItems,"sort-by":"createdDate","sort-desc":true,"tbody-tr-class":_vm.rowClass,"no-local-sorting":!_vm.localSorting,"per-page":_vm.prPage,"current-page":_vm.currenPage},on:{"sort-changed":_vm.handleSortChanged},scopedSlots:_vm._u([{key:"cell(invoiceNumber)",fn:function(data){return [(data.item.importInfo.failed)?_c('span',[(_vm.isDuplication(data.item.importInfo))?_c('span',[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
                name: 'economics.invoices.edit',
                params: { id: data.item.id },
                query: data.item.importInfo.importFileName.includes('pdf')
                  ? { pdf: 'true' }
                  : {},
              }}},[_c('span',[_vm._v("#"+_vm._s(data.value))])])],1):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(
              ((_vm.T(
                'Web.InvoicesPage.ImportFailedWithReason',
                'Import failed with reason'
              )) + ": " + (data.item.importInfo.failedReason))
            ),expression:"\n              `${T(\n                'Web.InvoicesPage.ImportFailedWithReason',\n                'Import failed with reason'\n              )}: ${data.item.importInfo.failedReason}`\n            ",modifiers:{"hover":true,"left":true}}],staticClass:"text-danger",attrs:{"icon":"AlertTriangleIcon","size":"16"}})],1):(data.item.importInfo.completed)?_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
            name: 'economics.invoices.preview',
            params: { id: data.item.id },
          }}},[_c('span',[_vm._v("#"+_vm._s(data.value))])]):_c('span',[_vm._v("--")])]}},{key:"cell(issueDate)",fn:function(data){return [(data.item.importInfo.completed)?_c('span',[_vm._v(" "+_vm._s(_vm.format_date(data.item.issueDate)))]):_c('span',[_vm._v("--")])]}},{key:"cell(sellerName)",fn:function(data){return [(data.item.importInfo.completed)?_c('span',[_vm._v(" "+_vm._s(data.item.sellerName))]):_c('span',[_vm._v("--")])]}},{key:"cell(documentType)",fn:function(data){return [(data.item.importInfo.completed)?_c('span',[_vm._v(" "+_vm._s(_vm.getDocumentTypeDisplayText(data.item.documentType))+" ")]):_c('span',[_vm._v("--")])]}},{key:"cell(importInfo_importFileName)",fn:function(data){return [_c('span',{staticClass:"d-flex"},[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.importInfo.importFileName),expression:"data.item.importInfo.importFileName",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"FileTextIcon","size":"16"},on:{"click":function($event){return _vm.addInvoiceIdToClippBoard(data.item.id)}}})],1),_c('span',{staticClass:"text-primary"},[(data.item.importInfo.importSource == 'Upload')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T('Web.InvoicesPage.ImportSources.FromUpload', 'From upload')
              ),expression:"\n                T('Web.InvoicesPage.ImportSources.FromUpload', 'From upload')\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"ArrowUpCircleIcon","size":"16"}}):_vm._e(),(data.item.importInfo.importSource == 'Integration')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T(
                  'Web.InvoicesPage.ImportSources.FromIntegration',
                  'From integration'
                )
              ),expression:"\n                T(\n                  'Web.InvoicesPage.ImportSources.FromIntegration',\n                  'From integration'\n                )\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"LinkIcon","size":"16"}}):_vm._e(),(data.item.importInfo.importSource == 'Manual')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T(
                  'Web.InvoicesPage.ImportSources.Manually',
                  'Manually entered'
                )
              ),expression:"\n                T(\n                  'Web.InvoicesPage.ImportSources.Manually',\n                  'Manually entered'\n                )\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"Edit3Icon","size":"16"}}):_vm._e()],1)])]}},{key:"cell(reconciliation)",fn:function(data){return [(data.item.importInfo.importing)?_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("--")]),_vm._v(" ... "),_c('b-badge',{attrs:{"variant":"light"}},[_c('small',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.T("Web.InvoicesPage.Status.Importing", "Importing..."))+".... "),_c('b-spinner',{attrs:{"type":"grow","label":"Spinning","variant":"primary","small":""}})],1)])],1):_vm._e()]}},{key:"cell(progress)",fn:function(data){return [_c('div',{staticClass:"progress-bar-con"},[(data.item.importInfo.completed)?_c('b-progress',{key:data.item.id,attrs:{"show-value":""}},[_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T(
                  'Web.InvoicesPage.SystemReconciliationsTooltip',
                  'System generated reconciliations in [%]'
                )
              ),expression:"\n                T(\n                  'Web.InvoicesPage.SystemReconciliationsTooltip',\n                  'System generated reconciliations in [%]'\n                )\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"value":data.item.reconcilationInfo
                  .systemGeneratedReconciliationPercentage,"variant":"primary","label":((data.item.reconcilationInfo.systemGeneratedReconciliationPercentage) + "%")}}),_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T(
                  'Web.InvoicesPage.ManuallyReconciliationsToolTip',
                  'Manually created reconciliations in [%]'
                )
              ),expression:"\n                T(\n                  'Web.InvoicesPage.ManuallyReconciliationsToolTip',\n                  'Manually created reconciliations in [%]'\n                )\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"value":data.item.reconcilationInfo.manuallyReconciliationPercentage,"label":((data.item.reconcilationInfo.manuallyReconciliationPercentage) + "%"),"variant":"warning"}})],1):(_vm.isDuplication(data.item.importInfo))?_c('small',[_vm._v(" Fakturanr. "),_c('strong',[_vm._v(_vm._s(data.item.invoiceNumber))]),_vm._v(" eksisterer allerede ")]):(data.item.importInfo.failed)?_c('b-progress',[_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(
                ((_vm.T('Web.InvoicesPage.ImportFailedWithReason')) + ": " + (data.item.importInfo.failedReason))
              ),expression:"\n                `${T('Web.InvoicesPage.ImportFailedWithReason')}: ${\n                  data.item.importInfo.failedReason\n                }`\n              ",modifiers:{"hover":true,"left":true}}],attrs:{"label":'!',"value":100,"variant":"danger"}})],1):_c('b-progress',{attrs:{"value":100,"show-value":"","animated":""}},[_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.T(
                  'Web.InvoicesPage.ImportingProgessTooltip',
                  'Importing in progress... (be aware PDF-extraction can take a long to complete)'
                )
              ),expression:"\n                T(\n                  'Web.InvoicesPage.ImportingProgessTooltip',\n                  'Importing in progress... (be aware PDF-extraction can take a long to complete)'\n                )\n              ",modifiers:{"hover":true,"top":true}}],attrs:{"value":100,"label":_vm.T('Web.InvoicesPage.Status.Importing', 'Importing...')}})],1)],1)]}},{key:"cell(actions)",fn:function(data){return [(data.item.importInfo.completed)?_c('span',{staticClass:"d-flex justify-content-end"},[(data.item.reconcilationInfo.reconcilationStatus == 'Draft')?[_c('b-button',{attrs:{"size":"sm","variant":"flat-primary"},on:{"click":function($event){return _vm.openReconciliationModal(data.item.id)}}},[_vm._v(" "+_vm._s(_vm.T("Web.InvoicesPage.Reconcile", "Reconcile"))+" "),_c('feather-icon',{attrs:{"icon":"ChevronsRightIcon","size":"16"}})],1),_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","text":_vm.T('Web.Generic.Actions', 'Actions')}},[_c('b-dropdown-item',{attrs:{"active":false},on:{"click":function($event){return _vm.openReconciliationModal(data.item.id)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T("Web.InvoicesPage.Reconcile", "Reconcile"))+" "),_c('feather-icon',{attrs:{"icon":"ListIcon","size":"16"}})],1)]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'economics.invoices.edit',
                  params: { id: data.item.id },
                  query: data.item.importInfo.importFileName.includes('pdf')
                    ? { pdf: 'true' }
                    : {},
                }}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Edit", "Edit"))+" "),_c('feather-icon',{attrs:{"icon":"Edit2Icon","size":"16"}})],1)]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.removeInvoice(data.item.id)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Remove"))+" "),_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1)]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.reRunImport(data.item)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" Genkør import "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"RefreshCcwIcon","size":"16"}})],1)])],1)]:[_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","text":_vm.T('Web.Generic.Actions', 'Actions')}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.openReconciliationModal(data.item.id)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoicesPage.ViewReconciliation", "View reconciliation" ))+" "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"SearchIcon","size":"16"}})],1)]),(_vm.isSystemAdmin)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleReopenReconciliation(data.item.id)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoicesPage.ReopenReconciliation", "Reopen reconciliation" ))+" "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"SkipBackIcon","size":"16"}})],1)]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.handleDownloadAccountDistribution(
                    data.item.id,
                    data.item.invoiceNumber
                  )}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoicesPage.DownloadAccountAllocation", "Download account allocation" ))+" "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"DownloadIcon","size":"16"}})],1)]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openPostingOverviewModal(data.item.id)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoicesPage.ShowPostingOverview", "Show posting overview" ))+" "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ListIcon","size":"16"}})],1)])],1)]],2):_c('span',[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(
                data.item.importInfo.failed &&
                data.item.importInfo.importStatus == 'Failed' &&
                !_vm.isDuplication(data.item.importInfo)
              )?_c('small',[_c('a',{staticClass:"text-danger font-weight-bold mr-1",on:{"click":function($event){return _vm.openFeedbackModalForFailedImportJob(data.item)}}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.SendFeedback", "Send feedback"))+" "),_c('feather-icon',{attrs:{"icon":"SendIcon","size":"16"}})],1)]):_vm._e(),(
                data.item.importInfo.failed &&
                data.item.importInfo.importStatus == 'FailedAndReported'
              )?_c('small',[_c('a',{staticClass:"text-danger font-weight-bold mr-1"},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.FeedbackSent", "Feedback sent"))+" ")])]):_vm._e(),_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","text":_vm.T('Web.Generic.Actions', 'Actions')}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteInvoice({ resourceId: data.item.id })}}},[_vm._v(" "+_vm._s(_vm.T("Web.Generic.Remove"))+" "),_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.reRunImport(data.item)}}},[_c('span',{staticClass:"d-flex justify-content-between"},[_vm._v(" Genkør import "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"RefreshCcwIcon","size":"16"}})],1)])],1)],1)])]}},{key:"cell(attachedLocationId)",fn:function(data){return [(
            !_vm.isSuperAdmin ||
            data.item.reconcilationInfo.reconcilationStatus != 'Draft'
          )?_c('span',[_vm._v(_vm._s(_vm.getLocationNameFromId(data.value)))]):(data.item.importInfo.failed)?_c('span'):_c('div',{staticStyle:{"width":"200px"}},[_c('treeselect',{attrs:{"id":("picker_" + (data.item.id)),"options":_vm.locationOptions,"disable-branch-nodes":true,"clearable":false,"value":data.value == '' ? null : data.value},on:{"select":function (location) { return _vm.updateLocation(location.id, data.item.id); }}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header d-flex justify-content-between">
        <div>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
        </div>
        <div>
          <feather-icon
            size="20"
            icon="MinimizeIcon"
            v-if="filterSettingsVisible == true && !filterMode"
            @click="filterSettingsVisible = false"
          />
          <feather-icon
            size="20"
            icon="FilterIcon"
            @click="filterSettingsVisible = true"
            v-else-if="!filterMode && !editMode"
          />
        </div>
      </div>
      <div class="body">
        <div class="filter-settings mt-1" v-if="filterSettingsVisible">
          <div class="d-flex justify-content-between">
            <div class="w-25 mr-3">
              <b-form-group label="Datakilde">
                <b-form-select
                  :options="dataSourceOptions"
                  v-model="item.settings.dataSource"
                  @change="fetchData"
                />
              </b-form-group>
            </div>
            <div class="w-100">
              <b-form-group label="Periode">
                <periode-picker
                  @periodchanged="fetchData"
                  v-model="item.settings.periodeScope"
                  :disabled="editMode"
                  :excludeForeverOption="true"
                />
              </b-form-group>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <div>
              <locations-mulit-select
                :locations="locations"
                v-model="item.settings.locationsFilter"
                @input="fetchData"
              />
            </div>
          </div>
        </div>
        <div class="table-container">
          <page-loader :loading="fetching">
            <div class="w-100">
              <div class="cat-divider">
                <div>Mængder</div>
                <div>Økonomi</div>
              </div>
              <b-table :fields="tableFields" :items="data" small>
                <template #cell(collectionsCount)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(collectionsCountYearBefore)="data">
                  {{
                    getDataYearBefore(data.item.id, "collectionsCount")
                      | toCurrency
                  }}
                </template>

                <template #cell(wasteCharge)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(chageTotalPrice)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(chageTotalPriceYearBefore)="data">
                  {{
                    getDataYearBefore(data.item.id, "chageTotalPrice")
                      | toCurrency
                  }}
                </template>
                <template #cell(incomeTotalPrice)="data">
                  {{ data.value | toCurrency }}
                </template>




                
                <template #cell(wasteChargeYearBefore)="data">
                  {{
                    getDataYearBefore(data.item.id, "wasteCharge") | toCurrency
                  }}
                </template>

                <template #cell(tonnageCollected)="data">
                  {{ formatCurrency(data.value, 3) }}
                </template>
                <template #cell(tonnageCollectedYearBefore)="data">
                  {{
                    getDataYearBefore(data.item.id, "tonnageCollected")
                      | toCurrency
                  }}
                </template>
                <template #cell(collectionCost)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(collectionCostYearBefore)="data">
                  {{
                    getDataYearBefore(data.item.id, "collectionCost")
                      | toCurrency
                  }}
                </template>

                <template #cell(rent)="data">
                  {{ data.value | toCurrency }}
                </template>
                
                <template #cell(rentYearBefore)="data">
                  {{ getDataYearBefore(data.item.id, "rent") | toCurrency }}
                </template>

                <template #cell(incomeTotalPriceYearBefore)="data">
                  {{ getDataYearBefore(data.item.id, "incomeTotalPrice") | toCurrency }}


                  
                </template>

                <template #cell(distributionTons)="data">
                  {{ calcProcentageOf(data.item.tonnageCollected, totalTons) }}
                </template>
                <template #cell(distributionTonsYearBefore)="data">
                  {{
                    calcProcentageOf(
                      getDataYearBefore(data.item.id, "tonnageCollected"),
                      totalTonsYearBefore
                    )
                  }}
                </template>

                <template #cell(ecoSum)="data">
                  {{
                    (data.item.collectionCost +
                      data.item.wasteCharge +
                      data.item.rent)
                      | toCurrency
                  }}
                </template>
                <template #cell(ecoSumYearBefore)="data">
                  {{
                    (getDataYearBefore(data.item.id, "collectionCost") +
                      getDataYearBefore(data.item.id, "wasteCharge") +
                      getDataYearBefore(data.item.id, "rent"))
                      | toCurrency
                  }}
                </template>

                <template #cell(distributionEco)="data">
                  {{
                    calcProcentageOf(
                      data.item.collectionCost +
                        data.item.wasteCharge +
                        data.item.rent,
                      totalEco
                    ) | toCurrency
                  }}
                </template>

                <template #cell(distributionEcoYearBefore)="data">
                  {{
                    calcProcentageOf(
                      getDataYearBefore(data.item.id, "collectionCost") +
                        getDataYearBefore(data.item.id, "wasteCharge") +
                        getDataYearBefore(data.item.id, "rent"),
                      totalEcoYearBefore
                    ) | toCurrency
                  }}
                </template>
              </b-table>
              <div class="d-flex justify-content-between">
                <div class="other-expenses">
                  <div>
                    <div class="font-weight-bold">+ Øvrige posteringer</div>
                    <div>{{ totalOtherExpenses | toCurrency }}</div>
                  </div>
                  <div v-if="totalFutileDrivingsCost > 0">
                    <div>Forgæves kørsel</div>
                    <div>{{ totalFutileDrivingsCost | toCurrency }}</div>
                  </div>
                  <div v-if="totalOilSurchargeFee > 0">
                    <div>Olietillæg</div>
                    <div>{{ totalOilSurchargeFee | toCurrency }}</div>
                  </div>
                  <div v-if="totalOtherPostings > 0">
                    <div>Div.</div>
                    <div>{{ totalOtherPostings | toCurrency }}</div>
                  </div>
                </div>
                <div class="mr-1">
                  <b-button
                    size="sm"
                    variant="light"
                    @click.prevent="exportToExcel"
                  >
                    <feather-icon icon="DownloadIcon" />
                    Eksporter</b-button
                  >
                </div>
              </div>
            </div>
          </page-loader>
        </div>
      </div>
    </div>
  </base-widget>
</template>

<script>
import { mapGetters } from "vuex";

import * as XLSX from "xlsx";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import moment from "moment";
import ResourceService from "@/services/base/resource.service";
import BaseWidget from "../common/BaseWidget.vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import LocationsMulitSelect from "../common/LocationsMulitSelect.vue";
import {
  BButton,
  BDropdownItem,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
export default {
  components: {
    BaseWidget,
    BButton,
    BDropdownItem,
    BCardTitle,
    BCardSubTitle,
    BTable,
    PeriodePicker,
    BFormSelect,
    BFormGroup,
    PageLoader,
    LocationsMulitSelect,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return "Procentfordeling";
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    totalChageTotalPrice() {
      return this.data.reduce(
        (prev, curr) => (prev += curr.chageTotalPrice),
        0
      );
    },
    totalIncomeTotalPrice(){
      return this.data.reduce(
        (prev, curr) => (prev += curr.incomeTotalPrice),
        0
      );
    },
    totalTons() {
      return this.data.reduce(
        (prev, curr) => (prev += curr.tonnageCollected),
        0
      );
    },
    totalTonsYearBefore() {
      return this.dataYearBefore.reduce(
        (prev, curr) => (prev += curr.tonnageCollected),
        0
      );
    },
    totalWasteCollections() {
      return this.data.reduce(
        (prev, curr) => (prev += curr.collectionsCount),
        0
      );
    },
    totalCollectionCost() {
      return this.data.reduce((prev, curr) => (prev += curr.collectionCost), 0);
    },
    totalCollectionCostYearBefore() {
      return this.data.reduce(
        (prev, curr) => (prev += curr.collectionCost),
        0
      );
    },
    totalCharge() {
      return this.data.reduce((prev, curr) => (prev += curr.wasteCharge), 0);
    },
    totalChargeYearBefore() {
      return this.dataYearBefore.reduce(
        (prev, curr) => (prev += curr.wasteCharge),
        0
      );
    },
    totalChageTotalPriceYearBefore()
    {
      return this.dataYearBefore.reduce(
        (prev, curr) => (prev += curr.chageTotalPrice),
        0
      );
    },
    totalIncomeTotalPriceYearBefore()
    {
      return this.dataYearBefore.reduce(
        (prev, curr) => (prev += curr.incomeTotalPrice),
        0
      );
    },

    
    totalRent() {
      return this.data.reduce((prev, curr) => (prev += curr.rent), 0);
    },
    totalRentYearBefore() {
      return this.dataYearBefore.reduce((prev, curr) => (prev += curr.rent), 0);
    },
    totalEco() {
      return this.data.reduce(
        (prev, curr) =>
          (prev += curr.collectionCost + curr.wasteCharge + curr.rent),
        0
      );
    },
    totalEcoYearBefore() {
      return this.dataYearBefore.reduce(
        (prev, curr) =>
          (prev += curr.collectionCost + curr.wasteCharge + curr.rent),
        0
      );
    },
    totalFutileDrivingsCost() {
      return this.data.reduce((prev, curr) => (prev += curr.futileDriving), 0);
    },
    totalOilSurchargeFee() {
      return this.data.reduce(
        (prev, curr) => (prev += curr.oilSurchargeFee),
        0
      );
    },
    totalOtherPostings() {
      return this.data.reduce((prev, curr) => (prev += curr.otherPostings), 0);
    },
    totalOtherExpenses() {
      return this.data.reduce(
        (prev, curr) =>
          (prev +=
            curr.futileDriving + curr.oilSurchargeFee + curr.otherPostings),
        0
      );
    },
    tableFields() {
      return [
        {
          key: "name",
          label:
            this.item.settings.dataSource == "Infocards"
              ? "Infokort"
              : "Fraktion",
          sortable: true,
        },
        {
          key: "collectionsCount",
          label: `Antal tøm (${this.formatCurrency(
            this.totalWasteCollections,
            2
          )})`,
          sortable: true,
        },
        {
          key: "collectionsCountYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalWasteCollections,
            2
          )})`,
          sortable: false,
        },
        {
          key: "tonnageCollected",
          label: `Antal tons (${this.formatCurrency(this.totalTons, 3)})`,
          sortable: true,
        },
        {
          key: "tonnageCollectedYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalTonsYearBefore,
            3
          )})`,
          sortable: false,
        },
        {
          key: "distributionTons",
          label: "%-fordeling af total tons (100)",
          sortable: true,
        },
        {
          key: "distributionTonsYearBefore",
          label: "Året før (100)",
          sortable: false,
        },
        {
          key: "divider",
          label: ``,
          sortable: false,
        },
        {
          key: "collectionCost",
          label: `Tøm (${this.formatCurrency(this.totalCollectionCost, 2)})`,
          sortable: true,
        },
        {
          key: "collectionCostYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalCollectionCostYearBefore,
            2
          )})`,
          sortable: false,
        },
        {
          key: "chageTotalPrice",
          label: `Afgift (${this.formatCurrency(
            this.totalChageTotalPrice,
            2
          )})`,
          sortable: true,
        },
        {
          key: "chageTotalPriceYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalChageTotalPriceYearBefore,
            2
          )})`,
          sortable: false,
        },
        {
          key: "incomeTotalPrice",
          label: `Indkomst (${this.formatCurrency(
            this.totalIncomeTotalPrice,
            2
          )})`,
          sortable: true,
        },
        {
          key: "incomeTotalPriceYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalIncomeTotalPriceYearBefore,
            2
          )})`,
          sortable: false,
        },
        {
          key: "rent",
          label: `Leje (${this.formatCurrency(this.totalRent, 2)})`,
          sortable: true,
        },
        {
          key: "rentYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalRentYearBefore,
            2
          )})`,
          sortable: false,
        },
        {
          key: "ecoSum",
          label: `Sum (${this.formatCurrency(this.totalEco, 2)})`,
          sortable: true,
        },
        {
          key: "ecoSumYearBefore",
          label: `Året før (${this.formatCurrency(
            this.totalEcoYearBefore,
            2
          )})`,
          sortable: false,
        },
        {
          key: "distributionEco",
          label: "%-fordeling af total økonomi (100)",
          sortable: true,
        },
        {
          key: "distributionEcoYearBefore",
          label: "Året før (100)",
          sortable: false,
        },
      ];
    },
    periodeScope() {
      return this.item.settings.periodeScope;
    },
    dataSource() {
      return this.item.settings.dataSource;
    },
    locationsFilter() {
      return this.item.settings.locationsFilter;
    },
  },
  data() {
    return {
      filterMode: false,
      fetching: true,
      data: [],
      dataYearBefore: [],
      filterSettingsVisible: false,
      dataSourceOptions: [
        {
          text: "Infokort",
          value: "Infocards",
        },
        {
          text: "Fraktion",
          value: "WasteFractions",
        },
      ],
    };
  },
  methods: {
    exportToExcel() {
      let period = TimeSpanHelper.getFromAndToDateFromPeriodeScope(
        this.item.settings.periodeScope
      );
      const fileName = `Procentfordeling ${period.fromDate}_${period.toDate}.xlsx`;
      const modifiedData = this.data.map((item) => {
        if (this.dataSource == "Infocards") {
          return {
            Infokortnavn: item.name,
            "Antal tøm": this.formatCurrency(item.collectionsCount, 2),
            "Antal tøm året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionsCount"),
              2
            ),
            "Antal tons": this.formatCurrency(item.tonnageCollected, 3),
            "Antal tons året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "tonnageCollected"),
              3
            ),
            "%-fordeling af total tons": this.calcProcentageOf(
              item.tonnageCollected,
              this.totalTons
            ),
            "%-fordeling af total tons året før": this.calcProcentageOf(
              this.getDataYearBefore(item.id, "tonnageCollected"),
              this.totalTonsYearBefore
            ),
            "": "",
            Tøm: this.formatCurrency(item.collectionCost, 2),
            "Tøm året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionCost"),
              2
            ),
            Afgift: this.formatCurrency(item.chageTotalPrice, 2),
            "Afgift året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "chageTotalPrice"),
              2
            ),
            Indtægt: this.formatCurrency(item.incomeTotalPrice, 2),
            "Indtægt året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "incomeTotalPrice"),
              2
            ),
            Leje: this.formatCurrency(item.rent, 2),
            "Leje året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "rent"),
              2
            ),
            Sum: this.formatCurrency(
              item.collectionCost + item.wasteCharge + item.rent,
              2
            ),
            "Sum året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionCost") +
                this.getDataYearBefore(item.id, "wasteCharge") +
                this.getDataYearBefore(item.id, "rent"),
              2
            ),
            "%-fordeling af total økonomi": this.calcProcentageOf(
              item.collectionCost + item.wasteCharge + item.rent,
              this.totalEco
            ),
            "%-fordeling af total økonomi året før": this.calcProcentageOf(
              this.getDataYearBefore(item.id, "collectionCost") +
                this.getDataYearBefore(item.id, "wasteCharge") +
                this.getDataYearBefore(item.id, "rent"),
              this.totalEcoYearBefore
            ),
          };
        } else {
          return {
            Fraktionsnavn: item.name,
            "Antal tøm året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionsCount"),
              2
            ),
            "Antal tons": this.formatCurrency(item.tonnageCollected, 3),
            "Antal tons året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "tonnageCollected"),
              3
            ),
            "%-fordeling af total tons": this.calcProcentageOf(
              item.tonnageCollected,
              this.totalTons
            ),
            "%-fordeling af total tons året før": this.calcProcentageOf(
              this.getDataYearBefore(item.id, "tonnageCollected"),
              this.totalTonsYearBefore
            ),
            "": "",
            Tøm: this.formatCurrency(item.collectionCost, 2),
            "Tøm året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionCost"),
              2
            ),
            Afgift: this.formatCurrency(item.chageTotalPrice, 2),
            "Afgift året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "chageTotalPrice"),
              2
            ),
            Indtægt: this.formatCurrency(item.incomeTotalPrice, 2),
            "Indtægt året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "incomeTotalPrice"),
              2
            ),
            Leje: this.formatCurrency(item.rent, 2),
            "Leje året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "rent"),
              2
            ),
            Sum: this.formatCurrency(
              item.collectionCost + item.wasteCharge + item.rent,
              2
            ),
            "Sum året før": this.formatCurrency(
              this.getDataYearBefore(item.id, "collectionCost") +
                this.getDataYearBefore(item.id, "wasteCharge") +
                this.getDataYearBefore(item.id, "rent"),
              2
            ),
            "%-fordeling af total økonomi": this.calcProcentageOf(
              item.collectionCost + item.wasteCharge + item.rent,
              this.totalEco
            ),
            "%-fordeling af total økonomi året før": this.calcProcentageOf(
              this.getDataYearBefore(item.id, "collectionCost") +
                this.getDataYearBefore(item.id, "wasteCharge") +
                this.getDataYearBefore(item.id, "rent"),
              this.totalEcoYearBefore
            ),
          };
        }
      });
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Procentfordeling");
      XLSX.writeFile(workbook, fileName);
    },
    getDataYearBefore(id, property) {
      let item = this.dataYearBefore.find((i) => i.id == id);
      if (item == undefined || item == null) return 0;
      return item[property];
    },
    async fetchData() {
      if (
        this.item.settings.periodeScope.periode == "CustomPeriod" &&
        (this.item.settings.periodeScope.customFromDate == null ||
          this.item.settings.periodeScope.customToDate == null)
      )
        return;

      this.fetching = true;
      this.data = [];
      const service = new ResourceService(
        "concern/statistics/procentage-distribution"
      );
      let period = TimeSpanHelper.getFromAndToDateFromPeriodeScope(
        this.item.settings.periodeScope
      );
      let periodYearBefore = {
        fromDate: new Date(
          new Date(period.fromDate).setYear(
            new Date(period.fromDate).getFullYear() - 1
          )
        ).toLocaleDateString("en-Us"),
        toDate: new Date(
          new Date(period.toDate).setYear(
            new Date(period.toDate).getFullYear() - 1
          )
        ).toLocaleDateString("en-Us"),
      };

      if (this.item.settings.dataSource == "Infocards") {
        this.data = (
          await service.get("infocards", {
            ...period,
            locationIds: this.item.settings.locationsFilter,
          })
        ).data.data;
        this.dataYearBefore = (
          await service.get("infocards", {
            ...periodYearBefore,
            locationIds: this.item.settings.locationsFilter,
          })
        ).data.data;
      } else {
        this.data = (
          await service.get("fractions", {
            ...period,
            locationIds: this.item.settings.locationsFilter,
          })
        ).data.data;
        this.dataYearBefore = (
          await service.get("fractions", {
            ...periodYearBefore,
            locationIds: this.item.settings.locationsFilter,
          })
        ).data.data;
      }
      this.fetching = false;
    },
    async updateWidgetSettings() {
      const service = new ResourceService(
        `concern/user-dashboards/${this.dashboardId}/items`
      );
      service.patch(this.item.id, this.item);
    },
    formatCurrency(value, decimal = 2) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK",
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formatter.format(value).replace("kr.", "").replace("\u00A0", "");
    },
    calcProcentageOf(value, total) {
      if (value > 0) {
        return this.formatCurrency((value / total) * 100, 1);
      }
      return this.formatCurrency(0, 1);
    },
  },
  watch: {
    periodeScope: {
      handler(newValue, oldValue) {
        if (
          newValue.periode == "CustomPeriod" &&
          (!newValue.customFromDate || !newValue.customToDate)
        )
          return;
        this.updateWidgetSettings();
      },
      deep: true,
    },
    dataSource: {
      handler(newValue, oldValue) {
        this.updateWidgetSettings();
      },
      deep: true,
    },
    locationsFilter: {
      handler(newValue, oldValue) {
        this.updateWidgetSettings();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > .body {
    padding: 10px 0px;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    .table-container {
      display: flex;
      overflow-y: auto;
      max-height: 100%;
    }
    ::v-deep tbody tr td {
      font-size: 12px;
    }
    ::v-deep tbody tr td,
    ::v-deep thead tr th {
      &:nth-child(9) {
        border-left: 4px solid #ebe9f1;
      }
    }
    ::v-deep tbody tr td,
    ::v-deep thead tr th {
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(10),
      &:nth-child(12),
      &:nth-child(14),
      &:nth-child(16),
      &:nth-child(18) {
        font-weight: normal;
        font-size: 10px;
      }
    }

    ::v-deep tbody tr td,
    ::v-deep thead tr th {
      &:not(:first-child) {
        text-align: right;
      }
    }

    ::v-deep .form-group {
      margin-bottom: 0px;
    }
    .cat-divider {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
    .other-expenses {
      width: 350px;
      margin: 10px;
      > div {
        padding: 0px 5px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ebe9f1;
        &:first-child {
          border-top: 1px solid #ebe9f1;
          background-color: #f0eff5;
        }
        div {
          color: #6e6b7b;
        }
      }
    }
  }
}
::v-deep thead {
  position: sticky;
  top: 0;
  left: 0;
}
</style>
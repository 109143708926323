<template>
  <div>
    <content-modal
      body-class="p-0"
      size="xl"
      :id="`invoice-recon-modal-${componentId}`"
    >
      <edit-reconciliation-modal-inner
        v-if="activeReconciliationId != null"
        :reconciliationId="activeReconciliationId"
        @reconciliation-saved="handleReconciliationSaved"
      />
    </content-modal>
    <confirm-modal :refname="randomName" ref="quickactionmodalconfirmModal" />
    <posting-preview-modal ref="postingPreviewModal" />
    <send-failed-import-feedback-modal
      ref="sendFailedImportFeedbackModal"
    ></send-failed-import-feedback-modal>
    <b-overlay :show="blockedLoading" rounded="sm">
      <b-table
        responsive
        show-empty
        primary-key="id"
        :empty-text="
          emptyText == undefined
            ? this.T('Web.Generic.TableEmpty', 'No items to show')
            : emptyText
        "
        :fields="invoiceTableHeadersFields"
        :items="listItems"
        sort-by="createdDate"
        :sort-desc="true"
        :tbody-tr-class="rowClass"
        :no-local-sorting="!localSorting"
        @sort-changed="handleSortChanged"
        :per-page="prPage"
        :current-page="currenPage"
      >
        <template #cell(invoiceNumber)="data">
          <span v-if="data.item.importInfo.failed">
            <span v-if="isDuplication(data.item.importInfo)">
              <b-link
                :to="{
                  name: 'economics.invoices.edit',
                  params: { id: data.item.id },
                  query: data.item.importInfo.importFileName.includes('pdf')
                    ? { pdf: 'true' }
                    : {},
                }"
                class="font-weight-bold"
              >
                <span>#{{ data.value }}</span>
              </b-link>
            </span>
            <feather-icon
              v-else
              class="text-danger"
              icon="AlertTriangleIcon"
              size="16"
              v-b-tooltip.hover.left="
                `${T(
                  'Web.InvoicesPage.ImportFailedWithReason',
                  'Import failed with reason'
                )}: ${data.item.importInfo.failedReason}`
              "
            />
          </span>
          <b-link
            v-else-if="data.item.importInfo.completed"
            :to="{
              name: 'economics.invoices.preview',
              params: { id: data.item.id },
            }"
            class="font-weight-bold"
          >
            <span>#{{ data.value }}</span>
          </b-link>
          <span v-else>--</span>
        </template>
        <template #cell(issueDate)="data">
          <span v-if="data.item.importInfo.completed">
            {{ format_date(data.item.issueDate) }}</span
          >
          <span v-else>--</span>
        </template>
        <template #cell(sellerName)="data">
          <span v-if="data.item.importInfo.completed">
            {{ data.item.sellerName }}</span
          >
          <span v-else>--</span>
        </template>
        <template #cell(documentType)="data">
          <span v-if="data.item.importInfo.completed">
            {{ getDocumentTypeDisplayText(data.item.documentType) }}
          </span>
          <span v-else>--</span>
        </template>
        <template #cell(importInfo_importFileName)="data">
          <span class="d-flex">
            <span>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                v-b-tooltip.hover.top="data.item.importInfo.importFileName"
                @click="addInvoiceIdToClippBoard(data.item.id)"
              />
            </span>
            <span class="text-primary">
              <feather-icon
                v-if="data.item.importInfo.importSource == 'Upload'"
                v-b-tooltip.hover.top="
                  T('Web.InvoicesPage.ImportSources.FromUpload', 'From upload')
                "
                icon="ArrowUpCircleIcon"
                size="16"
              />
              <feather-icon
                v-if="data.item.importInfo.importSource == 'Integration'"
                v-b-tooltip.hover.top="
                  T(
                    'Web.InvoicesPage.ImportSources.FromIntegration',
                    'From integration'
                  )
                "
                icon="LinkIcon"
                size="16"
              />
              <feather-icon
                v-if="data.item.importInfo.importSource == 'Manual'"
                v-b-tooltip.hover.top="
                  T(
                    'Web.InvoicesPage.ImportSources.Manually',
                    'Manually entered'
                  )
                "
                icon="Edit3Icon"
                size="16"
              />
            </span>
          </span>
        </template>
        <template #cell(reconciliation)="data">
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="data.item.importInfo.importing"
          >
            <span>--</span>
            ...
            <b-badge variant="light"
              ><small class="text-primary">
                {{ T("Web.InvoicesPage.Status.Importing", "Importing...") }}....
                <b-spinner
                  type="grow"
                  label="Spinning"
                  variant="primary"
                  small /></small
            ></b-badge>
          </div>
        </template>
        <template #cell(progress)="data">
          <div class="progress-bar-con">
            <b-progress
              show-value
              v-if="data.item.importInfo.completed"
              :key="data.item.id"
            >
              <b-progress-bar
                v-b-tooltip.hover.top="
                  T(
                    'Web.InvoicesPage.SystemReconciliationsTooltip',
                    'System generated reconciliations in [%]'
                  )
                "
                :value="
                  data.item.reconcilationInfo
                    .systemGeneratedReconciliationPercentage
                "
                variant="primary"
                :label="`${data.item.reconcilationInfo.systemGeneratedReconciliationPercentage}%`"
              ></b-progress-bar>
              <b-progress-bar
                v-b-tooltip.hover.top="
                  T(
                    'Web.InvoicesPage.ManuallyReconciliationsToolTip',
                    'Manually created reconciliations in [%]'
                  )
                "
                :value="
                  data.item.reconcilationInfo.manuallyReconciliationPercentage
                "
                :label="`${data.item.reconcilationInfo.manuallyReconciliationPercentage}%`"
                variant="warning"
              ></b-progress-bar>
            </b-progress>
            <small v-else-if="isDuplication(data.item.importInfo)">
              Fakturanr.
              <strong>{{ data.item.invoiceNumber }}</strong> eksisterer allerede
            </small>
            <b-progress v-else-if="data.item.importInfo.failed">
              <b-progress-bar
                :label="'!'"
                :value="100"
                variant="danger"
                v-b-tooltip.hover.left="
                  `${T('Web.InvoicesPage.ImportFailedWithReason')}: ${
                    data.item.importInfo.failedReason
                  }`
                "
              ></b-progress-bar>
            </b-progress>
            <b-progress :value="100" show-value animated v-else>
              <b-progress-bar
                v-b-tooltip.hover.top="
                  T(
                    'Web.InvoicesPage.ImportingProgessTooltip',
                    'Importing in progress... (be aware PDF-extraction can take a long to complete)'
                  )
                "
                :value="100"
                :label="T('Web.InvoicesPage.Status.Importing', 'Importing...')"
              ></b-progress-bar>
            </b-progress>
          </div>
        </template>
        <template #cell(actions)="data">
          <span
            class="d-flex justify-content-end"
            v-if="data.item.importInfo.completed"
          >
            <template
              v-if="data.item.reconcilationInfo.reconcilationStatus == 'Draft'"
            >
              <b-button
                size="sm"
                variant="flat-primary"
                @click="openReconciliationModal(data.item.id)"
              >
                {{ T("Web.InvoicesPage.Reconcile", "Reconcile") }}
                <feather-icon icon="ChevronsRightIcon" size="16" />
              </b-button>
              <!--
              <b-button
                size="sm"
                variant="flat-primary"
                :to="{
                  name: 'economics-reconciliation',
                  params: { invoiceId: data.item.id, edit: 'true' },
                }"
              >
                {{ $t("Generic.Reconcile") }}
                <feather-icon icon="ChevronsRightIcon" size="16" />
              </b-button>
              -->
              <b-dropdown
                size="sm"
                variant="flat-secondary"
                :text="T('Web.Generic.Actions', 'Actions')"
              >
                <b-dropdown-item
                  :active="false"
                  @click="openReconciliationModal(data.item.id)"
                >
                  <span class="d-flex justify-content-between">
                    {{ T("Web.InvoicesPage.Reconcile", "Reconcile") }}
                    <feather-icon icon="ListIcon" size="16" />
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  :to="{
                    name: 'economics.invoices.edit',
                    params: { id: data.item.id },
                    query: data.item.importInfo.importFileName.includes('pdf')
                      ? { pdf: 'true' }
                      : {},
                  }"
                >
                  <span class="d-flex justify-content-between">
                    {{ T("Web.Generic.Edit", "Edit") }}
                    <feather-icon icon="Edit2Icon" size="16" />
                  </span>
                </b-dropdown-item>

                <b-dropdown-item @click="removeInvoice(data.item.id)">
                  <span class="d-flex justify-content-between">
                    {{ T("Web.Generic.Remove") }}
                    <feather-icon icon="Trash2Icon" size="16" />
                  </span>
                </b-dropdown-item>

                <b-dropdown-item @click="reRunImport(data.item)">
                  <span class="d-flex justify-content-between">
                    Genkør import
                    <feather-icon icon="RefreshCcwIcon" size="16" class="ml-1" />
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else>
              <b-dropdown
                size="sm"
                variant="flat-secondary"
                :text="T('Web.Generic.Actions', 'Actions')"
              >
                <b-dropdown-item @click="openReconciliationModal(data.item.id)">
                  <span class="d-flex justify-content-between">
                    {{
                      T(
                        "Web.InvoicesPage.ViewReconciliation",
                        "View reconciliation"
                      )
                    }}
                    <feather-icon icon="SearchIcon" class="ml-1" size="16" />
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="isSystemAdmin"
                  @click="handleReopenReconciliation(data.item.id)"
                >
                  <span class="d-flex justify-content-between">
                    {{
                      T(
                        "Web.InvoicesPage.ReopenReconciliation",
                        "Reopen reconciliation"
                      )
                    }}
                    <feather-icon icon="SkipBackIcon" class="ml-1" size="16" />
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="
                    handleDownloadAccountDistribution(
                      data.item.id,
                      data.item.invoiceNumber
                    )
                  "
                >
                  <span class="d-flex justify-content-between">
                    {{
                      T(
                        "Web.InvoicesPage.DownloadAccountAllocation",
                        "Download account allocation"
                      )
                    }}
                    <feather-icon icon="DownloadIcon" class="ml-1" size="16" />
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="openPostingOverviewModal(data.item.id)"
                >
                  <span class="d-flex justify-content-between">
                    {{
                      T(
                        "Web.InvoicesPage.ShowPostingOverview",
                        "Show posting overview"
                      )
                    }}
                    <feather-icon icon="ListIcon" class="ml-1" size="16" />
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </span>
          <span v-else>
            <div class="d-flex justify-content-end align-items-center">
              <small
                v-if="
                  data.item.importInfo.failed &&
                  data.item.importInfo.importStatus == 'Failed' &&
                  !isDuplication(data.item.importInfo)
                "
              >
                <a
                  class="text-danger font-weight-bold mr-1"
                  @click="openFeedbackModalForFailedImportJob(data.item)"
                >
                  {{ T("Web.Generic.SendFeedback", "Send feedback") }}
                  <feather-icon icon="SendIcon" size="16" />
                </a>
              </small>
              <small
                v-if="
                  data.item.importInfo.failed &&
                  data.item.importInfo.importStatus == 'FailedAndReported'
                "
              >
                <a class="text-danger font-weight-bold mr-1">
                  {{ T("Web.Generic.FeedbackSent", "Feedback sent") }}
                </a>
              </small>
              <b-dropdown
                size="sm"
                variant="flat-secondary"
                :text="T('Web.Generic.Actions', 'Actions')"
              >
                <b-dropdown-item
                  @click="deleteInvoice({ resourceId: data.item.id })"
                >
                  {{ T("Web.Generic.Remove") }}
                  <feather-icon icon="Trash2Icon" size="16" />
                </b-dropdown-item>
                <b-dropdown-item @click="reRunImport(data.item)">
                  <span class="d-flex justify-content-between">
                    Genkør import
                    <feather-icon icon="RefreshCcwIcon" size="16" class="ml-1" />
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </span>
        </template>
        <template #cell(attachedLocationId)="data">
          <span
            v-if="
              !isSuperAdmin ||
              data.item.reconcilationInfo.reconcilationStatus != 'Draft'
            "
            >{{ getLocationNameFromId(data.value) }}</span
          >
          <span v-else-if="data.item.importInfo.failed"></span>
          <div style="width: 200px" v-else>
            <treeselect
              :id="`picker_${data.item.id}`"
              :options="locationOptions"
              :disable-branch-nodes="true"
              :clearable="false"
              :value="data.value == '' ? null : data.value"
              @select="(location) => updateLocation(location.id, data.item.id)"
            />
          </div>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import { mapActions } from "vuex";
import authHelper from "@/auth";
import moment from "moment";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BSpinner,
  BTable,
  BLink,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { uuid } from "vue-uuid";
import SendFailedImportFeedbackModal from "@/views/economics/invoices/components/SendFailedImportFeedbackModal.vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import EditReconciliationModalInner from "../../reporting/components/EditReconciliationModalInner.vue";
import ResourceService from "@/services/base/resource.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PostingPreviewModal from "../PostingPreviewModal.vue";
export default {
  props: {
    items: {
      required: true,
    },
    emptyText: {
      default: undefined,
    },
    prPage: {
      required: false,
      default: 10000,
    },
    currenPage: {
      required: false,
      default: 1,
    },
  },
  components: {
    Treeselect,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BSpinner,
    BTable,
    BLink,
    VBTooltip,
    FeatherIcon,
    BBadge,
    BProgress,
    BProgressBar,
    BDropdown,
    BDropdownItem,
    ConfirmModal,
    BOverlay,
    SendFailedImportFeedbackModal,
    ContentModal,
    EditReconciliationModalInner,
    PostingPreviewModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      interval: undefined,
      randomName: uuid.v4(),
      localSorting: true,
      blockedLoading: false,
      activeReconciliationId: null,
      componentId: uuid.v4(),
      locations: [],
    };
  },
  mounted() {
    this.startsPollingForImportStatusChange();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    listItems() {
      return this.items.filter((i) => !i.hide);
    },
    isSuperAdmin() {
      return authHelper.isSuperAdmin();
    },
    locationOptions() {
      let list = this.locations.reduce(function (rv, x) {
        (rv[x["concernName"]] = rv[x["concernName"]] || {
          label: x["concernName"],
          children: [],
          id: x.concernId,
        }).children.push({
          id: x.id,
          name: x.name,
          label: x.name,
        });
        return rv;
      }, {});
      return Object.keys(list).map(function (key) {
        return list[key];
      });
    },
    invoiceTableHeadersFields() {
      return [
        {
          key: "invoiceNumber",
          label: this.T("Web.Generic.Invoices.InvoiceNumber"),
          sortable: true,
        },
        {
          key: "documentType",
          label: this.T("Web.Generic.Invoices.DocumentType", "Document type"),
          sortable: true,
        },
        {
          key: "issueDate",
          label: this.T("Web.Generic.Invoices.IssueDate", "Issue date"),
          sortable: true,
        },
        {
          key: "sellerName",
          label: this.T("Web.Generic.Invoices.SellerName", "Seller name"),
          sortable: true,
        },
        {
          key: "buyerName",
          label: this.T("Web.Generic.Invoices.BuyerName", "Buyer name"),
          sortable: true,
        },
        {
          key: "attachedLocationId",
          label: this.T("Web.Generic.Locations.Location"),
          sortable: true,
        },
        {
          key: "importInfo_importFileName",
          label: this.T("Web.Generic.File", "File"),
          sortable: true,
        },
        {
          key: "progress",
          label: this.T("Web.Generic.Status"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ];
    },
    invoicesBeeingImported() {
      return this.items.filter((i) => i.importInfo.importing);
    },
  },
  async created() {
    const service = new ResourceService("background-data/locations/directory");
    this.locations = (await service.get("")).data.data;
  },
  methods: {
    ...mapActions({
      updateInvoicesOnList: "invoiceImports/updateInvoicesOnList",
      deleteInvoice: "invoiceImports/delete",
      confirmReconciliation: "invoiceImports/confirmReconciliation",
      reopenReconciliation: "invoiceImports/reopenReconciliation",
      removeFromList: "invoiceImports/removeFromList",
    }),
    isDuplication(job) {
      return (
        job.failedReason != null &&
        job.failedReason.startsWith("Document with invoice number")
      );
    },
    openPostingOverviewModal(id) {
      this.$refs.postingPreviewModal.open(id);
    },
    async handleDownloadAccountDistribution(id, invoiceNumber) {
      const service = new ResourceService("economics/reconciliations");
      const { data } = await service.get(`${id}/account-distribution`);
      console.log(data);
      let content = data.data.map((item) => {
        return {
          Group: 1,
          Company: item.locationAssetId,
          Date: moment(item.invoiceDate).format("yyyyMMDD"),
          Voucher: item.invoiceNumber,
          Currency: 2,
          Account: this.parseAccount(item.internalGroupId),
          "Curency amount": this.$options.filters.toCurrency(item.nettoCharge),
          "dim 1": this.parseDim1(item.internalGroupId),
          "dim 2": 13,
          "dim 3": this.parseDim3(item.internalGroupId),
          "tekst 1": item.supplierName,
          "tekst 2": item.infocardName,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(content);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `Kontofordeling - ${invoiceNumber}.xlsx`);
    },
    parseAccount(input) {
      if (input == null) return "--";
      if (input.includes("/")) return input.split("/")[0];
      return input;
    },
    parseDim1(input) {
      if (input == null) return "--";
      if (input.includes("/") && input.split("/").length > 1)
        return input.split("/")[1];
      return "";
    },
    parseDim3(input) {
      if (input == null) return "--";
      if (input.includes("/") && input.split("/").length > 2)
        return input.split("/")[2];
      return "";
    },

    parseAccountNo(name) {
      if (name == null) return "--";
      if (name.includes("-")) return name.split("-")[0];
      return name;
    },
    parseAccountDepartmentNo(name) {
      if (name == null) return "--";
      if (name.includes("-")) return name.split("-")[1];
      return name;
    },
    async updateLocation(locationId, invoiceId) {
      if (locationId == null || locationId == undefined) return;
      const service = new ResourceService("economics/invoices");
      await service.post(`${invoiceId}/location-and-concern`, {
        locationId: locationId,
      });
      let location = this.locations.find((l) => l.id == locationId);
      if (location.concernId != authHelper.getAccountId()) {
        this.removeFromList(invoiceId);
      }
    },
    getLocationNameFromId(id) {
      let location = this.locations.find((l) => l.id == id);
      if (location == null) return "";
      return location.name;
    },
    async addInvoiceIdToClippBoard(id) {
      await navigator.clipboard.writeText(id);
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`);
    },
    handleSortChanged(e) {
      console.log(e);
    },
    startsPollingForImportStatusChange() {
      if (this.invoicesBeeingImported["length"] == 0) return;
      this.interval = setInterval(() => {
        this.updateInvoicesOnList(this.invoicesBeeingImported);
      }, 2000);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return null;
      if (this.isDuplication(item.importInfo)) return "table-danger-dup";
      if (item.importInfo.importing) return "table-warning";
      if (item.importInfo.failed) return "table-danger";
    },
    openFeedbackModalForFailedImportJob(invoice) {
      this.$refs.sendFailedImportFeedbackModal.open(invoice);
    },
    async reRunImport(item)
    {
      const service = new ResourceService("economics/invoices/imports");
      await service.post(`${item.id}/re-run?useAttachment=true`);
      item.importInfo.importing = true;
      item.importInfo.completed = false;
      item.importInfo.importStatus = "Running";
    },
    async removeInvoice(id) {
      try {
        this.blockedLoading = true;
        await this.deleteInvoice({ resourceId: id });
      } finally {
        this.blockedLoading = false;
      }
    },
    async handleConfirmReconciliation(id) {
      if (!(await this.$refs.quickactionmodalconfirmModal.confirm())) return;
      try {
        this.blockedLoading = true;
        await this.confirmReconciliation(id);
      } finally {
        this.blockedLoading = false;
      }
    },
    async handleReopenReconciliation(id) {
      if (!(await this.$refs.quickactionmodalconfirmModal.confirm())) return;
      try {
        this.blockedLoading = true;
        await this.reopenReconciliation(id);
        this.openReconciliationModal(id);
        /*
        this.$router.push({
          name: "economics-reconciliation",
          params: { invoiceId: id, edit: "true" },
        });
        */
      } catch {
      } finally {
        this.blockedLoading = false;
      }
    },
    openReconciliationModal(id) {
      this.activeReconciliationId = id;
      this.$bvModal.show(`invoice-recon-modal-${this.componentId}`);
    },
    handleReconciliationSaved() {
      this.$emit("refresh-invoice-list");
      this.$bvModal.hide(`invoice-recon-modal-${this.componentId}`);
      this.activeReconciliationId = null;
    },
  },
  watch: {
    invoicesBeeingImported(newValue) {
      clearInterval(this.interval);
      if (Object.keys(newValue).length == 0) return;
      this.startsPollingForImportStatusChange();
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-con {
  width: 150px;
}
.progress-bar {
  font-size: 8px;
}
</style>

<style>
.table-danger-dup {
  background-color: #ebe9f1;
}
</style>


<template>
  <div class="dashboard-container">
    <confirm-modal ref="confirmModal" />
    <b-overlay :show="updating">
      <span v-if="loading"> {{ T("Web.Generic.Loading", "Loading") }}... </span>
      <template v-else>
        <content-modal
          id="widget-picker-modal"
          :title="T('Web.DashboardPage.WidgetStore.Title', 'Widget Store')"
          body-class="p-0"
          size="lg"
        >
          <widget-picker-modal-inner
            @widget-add-clicked="addWidgetToDashboard"
            :loading="updating"
          />
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-button
                variant="secondary"
                class="float-right"
                @click="$bvModal.hide('widget-picker-modal')"
              >
                {{ T("Web.Generic.Close") }}
              </b-button>
            </div>
          </template>
        </content-modal>
        <div class="header">
          <h3>
            <b-dropdown
              id="dropdown-1"
              :text="currentDashboard.title"
              variant="flat-secondary"
              :disabled="currentUserRole == 'FacilityUser1'"
            >
              <b-dropdown-item
                v-for="dashboard in dashboards"
                :key="dashboard.id"
                :disabled="currentDashboard.id == dashboard.id"
                @click="activateDashboard(dashboard)"
              >
                {{ dashboard.title }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="createNewEmptyDashboard"
                ><feather-icon icon="PlusIcon" class="mr-1" /> {{T('Web.DashboardPage.CreateNewEmptyDashboard', 'Create new empty dashboard')}}</b-dropdown-item
              >
              <b-dropdown-item @click="createNewDashboardFromCurrent"
                ><feather-icon icon="CopyIcon" class="mr-1" /> {{T('Web.DashboardPage.CreateNewDashboardFromCurrent', 'Create new dashboard from this')}}
              </b-dropdown-item>
            </b-dropdown>
          </h3>
          <div
            class="d-flex justify-content-between"
            v-if="canEditCurrentDashboard"
          >
            <b-button
              variant="flat-danger"
              size="sm"
              v-if="layoutEditMode"
              @click="removeCurrentDashboard"
              ><feather-icon icon="TrashIcon" class="mr-1" />{{T('Web.DashboardPage.DeleteDashboard', 'Delete dashboard')}}</b-button
            >
            <icon-button
              class="ml-1"
              :icon="layoutEditMode ? 'CheckIcon' : 'LayoutIcon'"
              @click="handleEditLayoutClicked"
            >
              {{
                layoutEditMode
                  ? T("Web.DashboardPage.SaveLayout", "Save layout")
                  : T("Web.DashboardPage.EditLayout", "Edit layout")
              }}
            </icon-button>
          </div>
        </div>
        <div class="body">
          <b-button
            variant="outline-secondary"
            class="w-100"
            v-if="layoutEditMode"
            v-b-modal.widget-picker-modal
          >
            {{ T("Web.DashboardPage.AddWidget", "Add widget") }}
            <feather-icon icon="PlusIcon"
          /></b-button>
          <dashboard id="home-dashboard">
            <dash-layout
              v-for="layout in dashboardLayout"
              v-bind="layout"
              :debug="false"
              :key="layout.breakpoint"
              :useCssTransforms="true"
              :compact="false"
              :margin="{ x: 25, y: 22 }"
            >
              <dash-item
                v-for="item in layout.items"
                v-bind.sync="item"
                :key="item.id"
                :locked="!layoutEditMode"
                :minWidth="item.settings.dimmensions.min.width"
                :minHeight="item.settings.dimmensions.min.height"
                :maxWidth="item.settings.dimmensions.max.width"
                :maxHeight="item.settings.dimmensions.max.height"
              >
                <component
                  v-bind:is="item.type"
                  :edit-mode="layoutEditMode"
                  :item="item"
                  :dashboard-id="currentDashboard.id"
                  @remove-clicked="removeWidget(item.id)"
                />
              </dash-item>
            </dash-layout>
          </dashboard>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import IconButton from "@/app/components/layout/buttons/IconButton.vue";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import WidgetPickerModalInner from "../components/WidgetPickerModalInner.vue";
import { mapActions, mapGetters } from "vuex";
import LineLoader from "@/app/components/layout/loading/LineLoader.vue";
import { uuid } from "vue-uuid";
import {
  BOverlay,
  BContainer,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import authHelper from "@/auth";
/** <--- Widgets --> */
import ConcernSummationWidget from "../widgets/ConcernSummationWidget/ConcernSummationWidget.vue";
import ConcernFractionsOverviewWidget from "../widgets/ConcernFractionsOverviewWidget/ConcernFractionsOverviewWidget.vue";
import ConcernOrdersOverviewWidget from "../widgets/ConcernOrdersOverviewWidget/ConcernOrdersOverviewWidget.vue";
import ConcernWasteCategoriesDistributionWidget from "../widgets/ConcernWasteCategoriesDistributionWidget/ConcernWasteCategoriesDistributionWidget.vue";
import ConcernInvoicedFractionPricesWidget from "../widgets/ConcernInvoicedFractionPricesWidget/ConcernInvoicedFractionPricesWidget.vue";
import ConcernWasteCategoryDistributionSimulatorWidget from "../widgets/ConcernWasteCategoryDistributionSimulatorWidget/ConcernWasteCategoryDistributionSimulatorWidget.vue";
import ConcernPostingsDistributionWidget from "../widgets/ConcernPostingsDistributionWidget/ConcernPostingsDistributionWidget.vue";
import ConcernFinancialOverviewWidget from "../widgets/ConcernFinancialOverviewWidget/ConcernFinancialOverviewWidget.vue";
import ConcernWasteFractionSortingComplianceWidget from "../widgets/ConcernWasteFractionSortingComplianceWidget/ConcernWasteFractionSortingComplianceWidget.vue";
import ConcernKyoceraUnimercoWidget from "../widgets/ConcernKyoceraUnimercoWidget/ConcernKyoceraUnimercoWidget.vue";
import ConcernUnitPricesWidget from "../widgets/ConcernUnitPricesWidget/ConcernUnitPricesWidget.vue";
import ConcernFractionSetsWidget from "../widgets/ConcernFractionSetsWidget/ConcernFractionSetsWidget.vue";
import ConcernInfocardCollectionsWidget from "../widgets/ConcernInfocardCollectionsWidget/ConcernInfocardCollectionsWidget.vue";
import ConcernInvoiceExportWidget from "../widgets/ConcernInvoiceExportWidget/ConcernInvoiceExportWidget.vue";
import ConcernPercentageDistributionWidget from "../widgets/ConcernPercentageDistributionWidget/ConcernPercentageDistributionWidget.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  components: {
    IconButton,
    Dashboard,
    DashLayout,
    DashItem,
    ContentModal,
    WidgetPickerModalInner,
    LineLoader,
    BOverlay,
    BContainer,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ConfirmModal,
    /** <--- Widgets --> */
    ConcernSummationWidget,
    ConcernInvoiceExportWidget,
    ConcernFractionsOverviewWidget,
    ConcernOrdersOverviewWidget,
    ConcernWasteCategoriesDistributionWidget,
    ConcernInvoicedFractionPricesWidget,
    ConcernWasteCategoryDistributionSimulatorWidget,
    ConcernPostingsDistributionWidget,
    ConcernFinancialOverviewWidget,
    ConcernWasteFractionSortingComplianceWidget,
    ConcernKyoceraUnimercoWidget,
    ConcernUnitPricesWidget,
    ConcernFractionSetsWidget,
    ConcernInfocardCollectionsWidget,
    ConcernPercentageDistributionWidget,
  },
  async created() {
    await this.loadDashbordAsync();
  },
  data() {
    return {
      layoutEditMode: false,
      loading: true,
      updating: false,
      activeDashboard: null,
      dashboardLayout: [
        {
          breakpoint: "xl",
          numberOfCols: 10,
          breakpointWidth: 1800,
          items: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ dashboards: "user/dashboards/list" }),
    currentDashboard() {
      return this.activeDashboard;
    },
    dashboardItems() {
      if (!this.currentDashboard) return [];
      return this.currentDashboard.dashboardItems;
    },
    widgetList() {
      return this.dashboardLayout[0].items;
    },
    currentUserRole() {
      return authHelper.getRole();
    },
    canEditCurrentDashboard() {
      return (
        !(this.currentUserRole == "FacilityUser1") &&
        this.currentDashboard.editable
      );
    },
    savedDashboardId: {
      get() {
        return this.getUserSetting(
          "concern-app.users.dashboards.savedId",
          null
        );
      },
      async set(value) {
        await this.setUserSettingAsync(
          "concern-app.users.dashboards.savedId",
          value
        );
      },
    },
    savedDashboard() {
      return this.dashboards.find((d) => d.id == this.savedDashboardId);
    },
  },
  methods: {
    ...mapActions({
      fetchDashboards: "user/dashboards/fetchList",
      createDashboard: "user/dashboards/create",
      updateDashboard: "user/dashboards/update",
      deleteDashboard: "user/dashboards/delete",
    }),
    async loadDashbordAsync() {
      this.loading = true;
      try {
        this.defaultDashboard = null;
        await this.fetchDashboards();
        //  if (this.dashboards.length == 0) await this.fetchDashboards();
        if (this.dashboards.length == 0) {
          // First time visit on account
          await this.createDashboard({ data: {} });
          await this.fetchDashboards();
        }
        if (this.savedDashboard) {
          this.activateDashboard(this.savedDashboard);
        } else {
          this.activeDashboard = this.dashboards[0];
          this.dashboardLayout[0].items = this.currentDashboard.dashboardItems;
        }
      } finally {
        this.loading = false;
      }
    },
    async createNewEmptyDashboard() {
      try {
        this.updating = true;
        const id = uuid.v4();
        await this.createDashboard({ resourceId: id, data: {} });
        await this.fetchDashboards();
        this.activeDashboard = this.dashboards.find((d) => d.id == id);
        this.dashboardLayout[0].items = this.currentDashboard.dashboardItems;
        this.savedDashboardId = id;
      } finally {
        this.updating = false;
      }
    },
    async createNewDashboardFromCurrent() {
      try {
        this.updating = true;
        const id = uuid.v4();
        const currentDashbaordItems = this.currentDashboard.dashboardItems;
        await this.createDashboard({ resourceId: id, data: {} });
        await this.fetchDashboards();
        this.activeDashboard = this.dashboards.find((d) => d.id == id);
        this.currentDashboard.dashboardItems = currentDashbaordItems;
        this.dashboardLayout[0].items = this.currentDashboard.dashboardItems;
        await this.updateDashboardLayout();
        this.savedDashboardId = id;
      } finally {
        this.updating = false;
      }
    },
    async removeCurrentDashboard() {
      if (!(await this.$refs.confirmModal.confirm())) return;
      try {
        this.updating = true;
        await this.deleteDashboard({ resourceId: this.currentDashboard.id });
        const dashboardToSwitchTo = this.dashboards.filter(
          (d) => d.id != this.currentDashboard.id
        )[0];
        this.activateDashboard(dashboardToSwitchTo);
      } finally {
        this.updating = false;
        this.layoutEditMode = false;
      }
    },
    activateDashboard(dashboard) {
      this.activeDashboard = dashboard;
      this.dashboardLayout[0].items = this.currentDashboard.dashboardItems;
      this.savedDashboardId = dashboard.id;
    },
    async addWidgetToDashboard(widget) {
      const widgetWidth = widget.settings.dimmensions.default.width;
      const widgetHeight = widget.settings.dimmensions.default.height;

      /** Find empty position to place widget */
      let dashboardHeight = 0;
      // Calculate height of dashboard
      dashboardHeight = this.dashboardLayout[0].items.reduce(
        (prev, curr) =>
          curr.y + curr.height > prev ? curr.y + curr.height : prev,
        0
      );

      let widgetPositionX = 0;
      let widgetPositionY = dashboardHeight;
      let spaceFound = false;
      for (
        var rowIndex = 0;
        rowIndex <= dashboardHeight && !spaceFound;
        rowIndex++
      ) {
        // Looking for empty space in row
        let widgetsInRow = this.dashboardLayout[0].items.filter(
          (i) => i.y <= rowIndex && i.y + i.height > rowIndex
        );
      }
      this.dashboardLayout[0].items.push({
        id: uuid.v4(),
        type: widget.type,
        x: widgetPositionX,
        y: widgetPositionY,
        width: widgetWidth,
        height: widgetHeight,
        settings: widget.settings,
      });
      this.$bvModal.hide("widget-picker-modal");
    },
    async removeWidget(id) {
      const item = this.dashboardLayout[0].items.find((i) => i.id == id);
      const indexToRemove = this.dashboardLayout[0].items.indexOf(item);
      this.dashboardLayout[0].items.splice(indexToRemove, 1);
    },
    async updateDashboardLayout() {
      try {
        this.updating = true;
        this.currentDashboard.dashboardItems = this.dashboardLayout[0].items;
        await this.updateDashboard({ data: this.currentDashboard });
      } finally {
        this.updating = false;
      }
    },
    async handleEditLayoutClicked() {
      if (this.layoutEditMode == true) {
        await this.updateDashboardLayout();
      }
      this.layoutEditMode = !this.layoutEditMode;
    },
  },
  watch: {
    widgetList: {
      async handler(newValue, oldValue) {
        if (oldValue == null) return;
        //await this.updateDashboardLayout();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;
    padding-bottom: 15px;
    margin-bottom: 10px;
    h3 {
      margin-bottom: 0px;
    }
  }
  .body {
    #home-dashboard {
      margin-right: -25px;
      margin-left: -25px;
    }
  }

  .dragging {
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.widget-title {
}
#home-dashboard {
  .placeholder {
    background-color: #e9e9e9 !important;
    opacity: 1 !important;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1400px) {
  .dashboard-container {
    .card-title {
      font-size: 12px;
    }
  }
}
</style>